import { useStore } from '@/store/index.js'
const store = useStore();

const fileURLDomain = store.state.permission.fileURLDomain;
const videoURLDomain = store.state.permission.videoURLDomain;
export function correctURL(type, url) {
  if (/^https?/.test(url)) {
    const aliDomainReg = /^https?.+?\.aliyuncs\.com\//;
    if (aliDomainReg.test(url)) {
      switch (type) {
        case 'video':
          return url.replace(aliDomainReg, videoURLDomain);
        case 'file':
          return url.replace(aliDomainReg, fileURLDomain);
      }
    } else {
      return url;
    }
  } else {
    switch (type) {
      case 'video':
        return `${videoURLDomain}${url}`;
      case 'file':
        return `${fileURLDomain}${url}`;
    }
  }
}