import md5 from 'blueimp-md5';
import { saveAs } from 'file-saver';
import ossRequest from '@/utils/ossRequest.js';

export function getExtWithDotFromFileName(fileNameStr) {
  let ext = '';
  if (fileNameStr && fileNameStr.includes('.')) {
    ext = `.${ fileNameStr.split('.').pop().toLowerCase() }`;
  }
  return ext;
}

export function getFileUniqueStr(file) {
  const {
    name,
    size,
    type
  } = file;
  return md5(`${ name }${ type }${ size }${ Date.now() }`);
}

export function saveOSSFile(fileURL, fileName) {
  return new Promise(resolve => {
    ossRequest({
      url: fileURL,
      method: 'get'
    }).then(res => {
      saveAs(
        new Blob([res], {
          type: "application/octet-stream",
        }),
        fileName
      );
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      resolve();
    });
  });
}