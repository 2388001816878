import axios from 'axios';

const request = axios.create({
  responseType: 'blob',
  timeout: 15000
});

request.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    console.log('Request error:', error);
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  response => {
    // 加载提示 - 关闭
    const res = response.data;

    return res;
  },
  error => {
    const message = window.$message;
    // 加载提示 - 关闭
    message.error(error instanceof Error ? error.message : error);
    console.log('Response error:', error);
    return Promise.reject(error);
  }
);

export default request;